import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';
import happy from '../../../../assets/happy.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class ProductsAssociatedModal extends React.Component {
    constructor(props) {
        super(props);
        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                // width: '30%',
                width: '40%',
                height: '80%'
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    handleCloseClick = () => {
        this.props.closeModal();
    }
    
    render() {
        const { modalIsOpen, closeModal, itemList, handleAddClick } = this.props;
        const { shop, mainColor } = this.props.myShopApiStore;

        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={this.handleCloseClick}>
                <div className={style.ModalContainer}>
                    <div className={style.Header}>
                        בחר/י את סוג התוספת שבא לך
                    </div>
                    <div className={style.CartProducts}>
                    {
                        itemList?.map((item, index) => {
                            const shekels = item?.unitTypes?.length ? item.unitTypes[0].price : ''
                            const hasProductOptions = (item && item.shopProductOptions) ? item.shopProductOptions.length > 0 : false;
                            const hasToppings = (item && item.shopToppings) ? item.shopToppings.length > 0 : false;
                            const isEditableItem = hasProductOptions || hasToppings;
                            return(
                                <div className={style.ItemContainer} key={index}
                                     onClick={() => {handleAddClick(item, isEditableItem); this.handleCloseClick();} }>
                                    <div className={style.ItemImage}>
                                        <LazyLoadImage effect='blur' 
                                            width="100%"
                                            src={item.product.imageKiosk ? item.product.imageKiosk : item.product.image}
                                        />
                                        <div className={style.PriceCircle} style={{backgroundColor: mainColor}}>
                                            <div className={style.Curr}>₪</div>
                                            <div className={style.Shekels}>{shekels}</div>
                                            <div className={style.Agorot}>00</div>
                                        </div>
                                    </div>
                                    <div className={style.ItemName} style={{color: mainColor}}>{item?.product?.name}</div>
                                </div>
                            )
                        })
                    }
                    </div>
                    <div className={style.NoThanksFooter}>
                        <span onClick={closeModal}>לא תודה</span>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default ProductsAssociatedModal;