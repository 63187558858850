import React from "react";
import NumberFormat from "react-number-format";
import style from "./style.module.css";

const SubForm2 = ({
  currentStep,
  handleChange,
  shopToppings,
  selectedToppings,
  toppingsDescription,
  toppingsFree
}) => {
  const curr = "₪";

  if (currentStep !== 2) {
    return null;
  }

  const displayToppingCost = (shopTopping) => {
    if(toppingsFree){ //for regular pack-item
      if(toppingsFree > selectedToppings.length){
        return ('ללא עלות');
      }
      else if(toppingsFree <= selectedToppings.length){
        for(let i=0 ; i < selectedToppings.length ; i++){
          if(shopTopping.id === selectedToppings[i].id){
            return i < toppingsFree
                    ? 
                      ('ללא עלות')
                    :
                      <NumberFormat
                        value={shopTopping.price}
                        prefix={curr}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                      />
          }
        }
      }
    }
    return !shopTopping.price
            ? 
              ('ללא עלות')
            :
              <NumberFormat
                value={shopTopping.price}
                prefix={curr}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
              />
  }

  console.log("subform2")

  return (
    <div className={style.Container}>
      <div className={style.ToppingsDescription}>{toppingsDescription}</div>
      <div className={style.ItemToppingList}>{shopToppings.map((st, idx) => {
          return (
            <div key={idx} className={style.ItemTopping}>
            <div className={style.WrapBtnAndName}>
              <input
                type="checkbox"
                value={st.topping.name}
                name="selectedToppings"
                checked={selectedToppings.some(topping => topping.name === st.topping.name)}
                onChange={handleChange}
              />
              <span className={style.ItemToppingName}>{st.topping.name}</span>
            </div>
              <span className={style.ItemToppingPrice}>
                {displayToppingCost(st)}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubForm2;
