import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import orderedIcon from 'assets/orderedIcon.png';
import orderedIconRed from 'assets/orderedIconRed.png';
import { CircularProgressbarWithChildren, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { isMobile } from 'react-device-detect';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class HistItem extends React.Component {
    constructor(props) {
        super(props)
        this.curr = '₪'
        
        this.props.isFirstOrder && window.innerWidth > 850 && this.handleClick();

        this.state = {
            isMobile: window.innerWidth <= 850,
        }
    }
  
    getFormatedDate = (timestamp) => {
        const date = new Date(timestamp * 1000)
        return date.toLocaleDateString('az-Latn-AZ');
    }

    getFormatedStatus = (status) => {
        switch(status){
            case 'new':
                return 'חדשה'
            case 'in_process':
                return 'בהכנה'
            case 'paid':
                return 'שולמה'
            case 'delivery':
                return 'במשלוח'
            case 'collected':
                return 'מוכנה'
            default:
                return '---'
        }
    }
    
    handleClick = () => {
        const { setBasketHistory } = this.props.myShopApiStore;
        const { order, showBasket, basketVisible, isFirstOrder  } = this.props;
        setBasketHistory(order);
        
        !basketVisible && showBasket();
    }

    updateIsMobile = () => {
        this.setState({ isMobile: window.innerWidth <= 850 });
    }

    componentWillUnmount = () => {
        window.addEventListener('resize', this.updateIsMobile);
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.updateIsMobile);
    }

    render() {
        const { order, getShippingStatus, basketVisible } = this.props;
        const { mainColor, basketHistory } = this.props.myShopApiStore;
        // const shippingStatus = getShippingStatus(order.id);
        const shippingStatus = null;

        return (
            <div className={style.container} onClick={this.handleClick}
                 style={{backgroundColor: basketHistory?.id === order?.id ? "#E0E0E0" : "#FFF"}}>
                <div className={style.TopDiv}>
                    <div className={style.info}>
                        <div className={style.infoRow}>
                            <div className={style.col}>
                                <div className={style.colDesc}>תאריך</div>
                                <div className={style.colVal} style={{color: mainColor}}>
                                    {this.getFormatedDate(order.createdDate)}
                                </div>
                            </div>
                            <div className={style.col}>
                                <div className={style.colDesc}>סכום הזמנה</div>
                                <div className={style.colVal} style={{color: mainColor}}>{`${this.curr}${order.totalPay.toFixed(2)}`}</div>
                            </div>
                            <div className={style.col}>
                                <div className={style.colDesc}>מספר הזמנה</div>
                                <div className={style.colVal} style={{color: mainColor}}>{order.id}</div>
                            </div>
                            <div className={style.col}>
                                <div className={style.colDesc}>סטטוס הזמנה</div>
                                <div className={style.colVal} style={{color: mainColor}}>
                                    {this.getFormatedStatus(order.status)}
                                    {/* {this.state.counter} */}
                                    {/* {order.status} */}
                                </div>
                            </div>
                        </div>
                        <hr className={style.line}/>
                        <div className={style.infoRow}>
                            {/* <div className={style.col}>
                                {
                                    order.orderType === 'delivery' ? 
                                    [ 
                                        <div className={style.colDesc}>יום הגעה</div>,
                                        <div className={style.colVal} style={{color: mainColor}}>
                                            {this.getFormatedDate(order.deliveryDate)}
                                        </div>
                                    ] : ''
                                }
                            </div>
                            <div className={style.col}>
                                {
                                    order.orderType === 'delivery' ? 
                                    [ 
                                        <div className={style.colDesc}>שעת הגעה</div>,
                                        <div className={style.colVal} style={{color: mainColor}}>
                                            {order.deliveryFrom}-{order.deliveryTo}
                                        </div>
                                    ] : ''
                                }
                            </div> */}
                            <div className={style.col}>
                                <div className={style.colDesc}>סוג הזמנה</div>
                                <div className={style.colVal} style={{color: mainColor}}>
                                    {
                                        order.orderType === 'delivery' ? "משלוח" : "איסוף עצמי"
                                    }
                                </div>
                            </div>
                            {/* <div className={style.col_del}>
                            {
                                shippingStatus
                                ?
                                    <>
                                        <div>
                                            <div className={style.colDesc}>זמן הגעה משוער</div>
                                            <div className={style.colDesc}>של השליח</div>
                                        </div>

                                        <div className={style.timerWrapper} style={{color: mainColor}}>
                                            <CircularProgressbarWithChildren styles={buildStyles({pathColor: '#ee0028'})} value={Math.ceil(shippingStatus.initialTime/60) - Math.ceil(shippingStatus.time/60)} strokeWidth={5} minValue={0} maxValue={Math.ceil(shippingStatus.initialTime/60)} size={1}>
                                                <div className={style.inner}>
                                                    <div className={style.time}>{Math.ceil(shippingStatus.time/60)}</div>
                                                    <div className={style.unit}>דק'</div>
                                                </div>
                                            </CircularProgressbarWithChildren>
                                        </div>
                                    </>
                                :
                                    null
                            }
                            </div> */}
                        </div>
                    </div>
                    <div className={style.image}>
                        {
                            order.status === 'paid'
                            ?
                                <img src={orderedIconRed} alt='' width='40px' />
                            :
                                <img src={orderedIcon} alt='' width='40px' />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default HistItem;