import React from 'react';
import { observer, inject } from 'mobx-react';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import Tooltip from "react-power-tooltip";
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import UnitTypes from './UnitTypes';
import SpecificationsModal from './SpecificationsModal';
import CarouselModal from 'Components/MainScreen/AppBody/ShopMenu/ItemList/Item/CarouselModal';
import brokenImg from 'assets/brokenImg.jpg'
import MoreItemInfoModal from './MoreItemInfoModal';
import ProductsAssociatedModal from 'Components/MainScreen/AppBody/ProductsAssociatedModal';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class Item extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            show: false,
            left: 0,
            top: 0,
            unitTypeSelected: null,
            specModalIsOpen: false,
            carouselModalIsOpen: false,
            moreItemInfoModalIsOpen: false,
            productsAssociatedModalIsOpen: false,
            productsAssociated: null
        }
    }

    closeSpecModal = () => {
        this.setState({specModalIsOpen: false});
    }
    openSpecModal = () => {
        this.setState({specModalIsOpen: true});
    }

    setUnitTypeSelected = (unitType) => {
        this.setState({unitTypeSelected: unitType})
    }

    setLoading = () => {
        this.setState(prevState => ({
            loading: !prevState.loading
        }));
    }

    getUnits(_item){
        const uType = _item ? _item.defaultUnitType : null;
        switch (uType) {
            case 'kg':
                return 'ק"ג';
            default:
                return "יח'";
        }
    }

    showTooltip = event => {
        this.setState({
            show: true,
            top: event.target.getBoundingClientRect().top - 20,
            left: event.target.getBoundingClientRect().left + 90,
        });
    }

    hideTooltip = event => {
        this.setState({
            show: false
        });
    }

    countItems = item => {
        const { cartItemList, getCart } = this.props.myShopApiStore;
        let count = 0;
        getCart().cartItemList.forEach(cartItem => {
            if(cartItem.item.id === item.id) count++
        })
        return count;
    }
    
    //return the item index (with same type) that added last to the cart;
    getIndexOfLastItemType = () => {
        const { item } = this.props;
        const { getCart } = this.props.myShopApiStore;
        let cartItemIndex = null;
        getCart().cartItemList.forEach((cartItem, idx) => {
            if(cartItem.item.id === item.id){
                cartItemIndex = idx;
            }
        })

        return cartItemIndex;
    }
    getItemAmount = () => {
        const { item } = this.props;
        const { getCart } = this.props.myShopApiStore;
        let count = 0;
        getCart().cartItemList.forEach(cartItem => {
            if(cartItem.item.id === item.id){
                const fixFloatingPoint = val => Number.parseFloat(val.toFixed(13))
                // cartItem.quantity = fixFloatingPoint(cartItem.quantity);
                count = fixFloatingPoint(count + cartItem.quantity)
                
                // count = Number.isInteger(count) ? count : count.toFixed(1);
            }
        });
        return count;
    }

    handleAddClick = async (itemAssociated = null) => {
        const { addToCart, isPackItemUpdating,
                isCartItemUpdating, getProductsAssociated } = this.props.myShopApiStore;
        const item = itemAssociated ? itemAssociated : this.props.item;

        const unitTypes = item.unitTypes ? item.unitTypes : null;
        let isDuringOrder = isPackItemUpdating || isCartItemUpdating;

        if(!this.state.unitTypeSelected){
            this.setUnitTypeSelected(unitTypes[0]);
        }

        if(!isDuringOrder){
            addToCart(item, this.state.unitTypeSelected ? this.state.unitTypeSelected : unitTypes[0])
        }

        ////////////
        isDuringOrder = this.props.myShopApiStore.isPackItemUpdating || this.props.myShopApiStore.isCartItemUpdating;
        if(!isDuringOrder){
            if(!item.shopProductsAssociatedIds || !item.shopProductsAssociatedIds?.length){
                return;
            }
            const resp = await getProductsAssociated(item.shopProductsAssociatedIds);
            if(resp?.cartProducts?.length){
                this.setState({productsAssociated: resp.cartProducts})
                this.openProductsAssociatedModal();
            }
        }
        ///////////
    }
    
    openProductsAssociatedModal = () => {
        this.setState({productsAssociatedModalIsOpen: true});
    }
    closeProductsAssociatedModal = () => {
        this.setState({productsAssociatedModalIsOpen: false});
    }

    openCarouselModal = () => {
        this.setState({carouselModalIsOpen: true});
    }

    closeCarouselModal = () => {
        this.setState({carouselModalIsOpen: false});
    }

    changeBorkenImg = e => {
        e.target.src = brokenImg;
    }

    openMoreItemInfoModal = () => {
        this.setState({moreItemInfoModalIsOpen: true})
    }
    closeMoreItemInfoModal = () => {
        this.setState({moreItemInfoModalIsOpen: false})
    }

    render() {
        const { item, index } = this.props;
        const { removeCartItem,
                getCart,
                closeCartItemForm,
                isCartItemUpdating,
                isPackItemUpdating,
                shop} = this.props.myShopApiStore;
        const product = item.product ? item.product : null;
        const unitTypes = item.unitTypes ? item.unitTypes : null;
        const curr = '₪';

        const units = this.getUnits(item);
        const desc = item.product ? item.product.description : null;
        const iNew = <div className={`${style.iFlags} ${style.iNew}`}><div>חדש!</div></div>;
        const iSale = <div className={`${style.iFlags} ${style.iSale}`}><div>במבצע!</div></div>;
        const iSeason = <div className={`${style.iFlags} ${style.iSeason}`}><div>עונה!</div></div>;

        const isItemInCart = getCart().cartItemList.some(cartItem => cartItem.item.id === item.id);
        const hasProductOption = item.shopProductOptions ? item.shopProductOptions.length : false;
        const isEditableItem = hasProductOption || item.shopToppings.length;
        const isPackItem = item.product.levels ? item.product.levels.length : false;
        const isDuringOrder = isPackItemUpdating || isCartItemUpdating;

        const { imageBig } = this.props.item.product;
        console.log("item->", item)
        return (
            <div className={style.Container}>
                <div className={style.modals}>
                {
                    imageBig && imageBig.length
                    ?
                        <CarouselModal modalIsOpen={this.state.carouselModalIsOpen} closeModal={this.closeCarouselModal} item={item}/>
                    :
                        ''
                }
                    <MoreItemInfoModal item={item} modalIsOpen={this.state.moreItemInfoModalIsOpen} closeModal={this.closeMoreItemInfoModal} />
                    <ProductsAssociatedModal  modalIsOpen={this.state.productsAssociatedModalIsOpen}
                                              closeModal={this.closeProductsAssociatedModal}
                                              itemList={this.state.productsAssociated}
                                              handleAddClick={this.handleAddClick}
                                              />
                </div>
                <div className={style.TopDiv} data-ind={index} data-code={item.codename}>
                    <div className={style.DivRight} onClick={this.openCarouselModal}>
                        <div className={style.ItemImg}>
                            <img className={style.ItemImg}
                                src={product ? product.image : ''}
                                alt=''
                                onError={this.changeBorkenImg}
                            />
                        </div>
                        <div className={style.ItemFlags}>
                            {
                                item.isNew ? iNew : ''
                            }
                            {
                                item.isSale ? iSale : ''
                            }
                            {
                                item.isSeason ? iSeason : ''
                            }
                        </div>
                    </div>
                    <div className={style.DivMid}>
                        <div className={style.ItemName}
                            ref={ref => { this.refItem = ref }}
                            style={{color: this.props.myShopApiStore.mainColor}}
                        >
                            <div>
                                { product ? product.name : '' }
                            </div>
                        </div>
                        <div className={style.ItemPrice}>
                            {
                                shop.isBusiness
                                ?
                                    ""
                                :
                                    unitTypes ?
                                        <NumberFormat value={this.state.unitTypeSelected ? this.state.unitTypeSelected.price : unitTypes[0].price} prefix={curr} decimalScale={2} fixedDecimalScale={true} displayType={'text'} />
                                    : ''
                            }
                        </div>
                        <div className={style.ItemUnits}>
                            {
                               
                                unitTypes
                                ? 
                                    unitTypes.length === 1
                                    ?
                                        <div className={style.Unit}>
                                            { unitTypes[0].type === 'kg' ? 'ק"ג' : unitTypes[0].type === 'unit' ? "יח'" : unitTypes[0].type}
                                        </div>
                                    :
                                        <UnitTypes unitTypes={unitTypes} item={item} setUnitTypeSelected={this.setUnitTypeSelected}/>
                                : 
                                    ''
                            }
                        </div>
                    </div>
                    <div className={style.DivLeft}>
                        <div className={style.DivLeftUp}>
                            <SpecificationsModal item={item} modalIsOpen={this.state.specModalIsOpen} closeModal={this.closeSpecModal}/>
                            {
                                item.product.technical_description
                                ?
                                    <div style={{cursor: 'pointer',color: this.props.myShopApiStore.mainColor, textDecoration: `underline ${this.props.myShopApiStore.mainColor}`}} onClick={this.openSpecModal}>מפרט טכני</div>
                                :
                                    null
                            }
                            {
                                desc ?
                                    <div className={style.DescOuterDiv} /*onClick={this.openMoreItemInfoModal}*/>
                                        <div onMouseEnter={this.showTooltip}
                                             onMouseLeave={this.hideTooltip}
                                             style={{color: this.props.myShopApiStore.mainColor, textDecoration: `underline ${this.props.myShopApiStore.mainColor}`}}
                                             className={style.label}
                                             >
                                            מידע נוסף
                                        </div>
                                        {
                                            this.state.show 
                                            ? 
                                                <div className={style.DescOuterPopup} style={{top: this.state.top, left: this.state.left}}>
                                                    <div className={style.DescArrow}></div>
                                                    <div className={style.DescArrow2}></div>
                                                    <div className={style.DescOuterPopupIn}>
                                                        <div className={style.DescPopupIcon}>
                                                        </div>
                                                        <div className={style.DescPopupText}>
                                                            {desc}
                                                        </div>
                                                    </div>
                                                </div>
                                            : 
                                                ''
                                        }
                                    </div>
                                : ''
                            }
                        </div>
                        {(isItemInCart && !isEditableItem && !isPackItem && this.getItemAmount() > 0) ?
                            (
                                <div className={style.DivLeftBottom} style={{backgroundColor: this.props.myShopApiStore.mainColor}}>
                                    <div className={style.PlusButton} onClick={() => this.handleAddClick()}>+</div>
                                    <div className={style.ItemAmount}>{this.getItemAmount()}</div>
                                    <div className={style.MinusButton} onClick={() => {
                                        if(!isDuringOrder){
                                            removeCartItem(this.getIndexOfLastItemType(), true);
                                            closeCartItemForm();}
                                        }}>-</div>
                                </div>
                            ) :
                            (
                                <div className={style.DivLeftBottom} onClick={() => this.handleAddClick()} style={{backgroundColor: this.props.myShopApiStore.mainColor}}>
                                    <div className={style.PlusLeftButton}>+</div>
                                    <div className={style.BtnLeftButton}>הוסף לסל</div>
                                </div>
                            )
                        }

                    </div>
                </div>
                {
                    item.isStock ? '' : 
                        <div className={style.DivNotInStock}>
                            <div>אזל במלאי!</div>
                        </div>
                }
            </div>
        )
    }
}

export default Item;
