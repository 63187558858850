import React from "react";
import { observer, inject } from "mobx-react";
import SubForm1 from "./SubForm1";
import SubForm2 from "./SubForm2";
import SubForm3 from "./SubForm3";
import style from "./style.module.css";
import ProductsAssociatedModal from '../../../ProductsAssociatedModal'
import SubForm0 from "./SubForm0";


@inject((store) => {
  const { myShopApiStore } = store;
  return { myShopApiStore };
})

@observer
class ItemUpdateForm extends React.Component {
  constructor(props) {
    super(props);
    const { isPackItemUpdating, cartItemUpdating, packItemUpdating } = this.props.myShopApiStore;
    const pItemHasProductOptions = packItemUpdating ? (packItemUpdating.item.shopProductOptions ? packItemUpdating.item.shopProductOptions.length : false) : false;
    const cItemHasProductOptions = cartItemUpdating ? (cartItemUpdating.item.shopProductOptions ? cartItemUpdating.item.shopProductOptions.length : false) : false;
    const cItemHasBaseToppings = cartItemUpdating ? (cartItemUpdating.item.shopBaseToppings ? cartItemUpdating.item.shopBaseToppings.length : false) : false;
    const pItemHasBaseToppings = packItemUpdating ? (packItemUpdating.item.shopBaseToppings ? packItemUpdating.item.shopBaseToppings.length : false) : false;
    this.state = {
      hasError: false,
      currentStep: isPackItemUpdating ? (pItemHasBaseToppings ? 0 : (pItemHasProductOptions ? 1 : 2)) : (cItemHasBaseToppings ? 0 : (cItemHasProductOptions ? 1 : 2)),
      selectedBaseToppings: isPackItemUpdating
                          ? (packItemUpdating.options ? packItemUpdating.options.baseToppings : [])
                          : (cartItemUpdating.options ? cartItemUpdating.options.baseToppings : []),
      selectedProductOption: isPackItemUpdating
                          ? (packItemUpdating.options ? packItemUpdating.options.productOption : {})
                          : (cartItemUpdating.options ? cartItemUpdating.options.productOption : {}),
      selectedToppings: isPackItemUpdating
                      ? (packItemUpdating.options ? packItemUpdating.options.toppings : [])
                      : (cartItemUpdating.options ? cartItemUpdating.options.toppings : []),
      optionsHistory: {
        productOption: this.saveOldProductOption(),
        toppings: this.saveOldToppings()
      },
    };
  }
  saveOldProductOption = () => {
    const { cartItemUpdating,
      packItemUpdating,
      isPackItemUpdating } = this.props.myShopApiStore;

    if(isPackItemUpdating){
      return packItemUpdating.options ? {...packItemUpdating.options.productOption} : {}
    }
    return cartItemUpdating.options ? {...cartItemUpdating.options.productOption} : {}
  }

  saveOldToppings = () => {
    const { cartItemUpdating,
            packItemUpdating,
            isPackItemUpdating } = this.props.myShopApiStore;

    let newToppings = [];
    if(isPackItemUpdating){
      packItemUpdating.options && packItemUpdating.options.toppings.forEach(t => {
        const newQuarterList = [...t.quarterList];
        newToppings.push({...t, quarterList: newQuarterList})
      })
    }
    else{
      cartItemUpdating.options && cartItemUpdating.options.toppings.forEach(t => {
        const newQuarterList = [...t.quarterList];
        newToppings.push({...t, quarterList: newQuarterList})
      })
    }

    return newToppings;
  }

  closeMaxToppingMsg = () => {
    this.setState({hasError: false})
  }
  
  handleSubmit = (event) => {
    event.preventDefault();
    const { closeCartItemForm,
            editCartItemUpdating,
            calcTotalCartPrice,
            isPackItemUpdating,
            closePackItemForm,
            cartItemUpdating,
            packItemUpdating,
            setStepNum
          } = this.props.myShopApiStore;
    
    //toppings that added to the pizza tray and not just chosen in checkbox
    const isPizza =  (packItemUpdating && packItemUpdating.item.product.productType === 'pizza') || (cartItemUpdating.item.product.productType === 'pizza');
    if(isPizza){
      const toppingsAddedToPizza = this.state.selectedToppings.filter(topping => topping.quarterList.some(q => q === true));
      editCartItemUpdating(this.state.selectedProductOption, toppingsAddedToPizza, this.state.selectedBaseToppings) 
    }
    calcTotalCartPrice();
    
    if(isPackItemUpdating){
      const isTheLastStep = cartItemUpdating.steps.length === this.props.currentStep;
      setStepNum(1);
      if(isTheLastStep){
        closePackItemForm();
        this.props.checkForProductsAssociated(this.props.myShopApiStore.cartItemUpdating.item, true); //Added!
        closeCartItemForm();
      }else{
        closePackItemForm();
        this.props.nextStep();
      }
    }else{
      this.props.checkForProductsAssociated(this.props.myShopApiStore.cartItemUpdating.item, true); //Added!
      closeCartItemForm();
    }

  };

  toggleToppingOnQuarter = (topping, quarterIdx) => {
    const { calcTotalCartPrice, editCartItemUpdating, isPackItemUpdating, packItemUpdating, cartItemUpdating} = this.props.myShopApiStore;
    const { maxToppings } = isPackItemUpdating ? packItemUpdating.item.product : cartItemUpdating.item.product;
    const { toppingsFree } = this.props;

    let toppingsAddPaid = null;
    if(isPackItemUpdating){
      toppingsAddPaid = packItemUpdating.toppingsAddPaid;
    }


////////////////

    if(topping && topping.id){
      // checks if maxTopping === '' (in interface management)
      const maxToppingsIsOff = maxToppings === null || maxToppings === undefined || maxToppings === 0; 

      //limit toppings
      const quartersAmount = this.getQuartersAmount(); 
      const isToppingOnQuarter = topping.quarterList[quarterIdx];

      if(!isToppingOnQuarter){
        if(((maxToppings*4) > quartersAmount || maxToppingsIsOff) &&
                  (toppingsAddPaid !== 0 || quartersAmount < (toppingsFree*4))){
          if(this.state.hasError){
            this.setState({hasError: false})
          }
        }
        else{
            if(!this.state.hasError){
              this.setState({hasError: true})
            }
            return;
        }
      }

      // const quartersAmount = this.getQuartersAmount(); 
      // if((((maxToppings*4) <= quartersAmount) || (toppingsAddPaid === 0 && (toppingsFree*4) <= quartersAmount)) && !topping.quarterList[quarterIdx] && !maxToppingsIsOff){
      //   !this.state.hasError && this.setState({hasError: true})
      //   return;
      // }
      // else{
      //   this.state.hasError && this.setState({hasError: false})
      // }


////////////////

      //switching (enable/disable) topping on quarter
      const { quarterList } = topping;
      const newQuarterList = [...quarterList]
      newQuarterList[quarterIdx] = !quarterList[quarterIdx]
  
      //updating 'selectedToppings' state with new topping (that with the new quarterList property)
      const newTopping = {...topping, quarterList: newQuarterList};
      let newSelectedToppings = [];
      this.state.selectedToppings.forEach(t => {
        if(t.id === newTopping.id){ ///CHANGED FROM t.codeName === newTopping.codeName 
          newSelectedToppings.push(newTopping);
        }
        else{
          const ql = [...t.quarterList] //new quarterList
          const newT = {...t, quarterList: ql}
          newSelectedToppings.push(newT);
        }
      })

      // //update firsts free toppings quarters price (for pack item)
      // if(this.props.toppingsFree){
      //   const quartersFree = this.props.toppingsFree * 4;
      //   for(let i=0 ; i<this.state.selectedToppings.length ; i++){

      //   }
      // }

      this.setState({
        selectedToppings: newSelectedToppings
      })
  
      //update option changes
      editCartItemUpdating(this.state.selectedProductOption, newSelectedToppings, this.state.selectedBaseToppings)
  
      //calculate total price
      calcTotalCartPrice();
    }
  }

  toggleToppingOnAllQuarters = (topping) => {
    const { calcTotalCartPrice, editCartItemUpdating, isPackItemUpdating, packItemUpdating, cartItemUpdating } = this.props.myShopApiStore;
    const { maxToppings } = isPackItemUpdating ? packItemUpdating.item.product : cartItemUpdating.item.product;
    const { toppingsFree } = this.props;
    
    let toppingsAddPaid = null;
    if(isPackItemUpdating){
      toppingsAddPaid = packItemUpdating.toppingsAddPaid;
    }


    if(topping && topping.id){

      // checks if topping is on all pizza quarters
      const onWholeTray = topping.quarterList.every(q => q);

      // checks if maxTopping === '' (in interface management) -> its mean that no have limited toppings amount!
      const maxToppingsIsOff = maxToppings === null || maxToppings === undefined || maxToppings === 0; 



      //////////////////////////////


      //limit toppings
      const quartersAmount = this.getQuartersAmount(); 
      const emptysToppingQuarters = topping.quarterList.filter(q => !q).length;

      if(!onWholeTray){
        if(((maxToppings*4) > quartersAmount || maxToppingsIsOff) &&
                  (toppingsAddPaid !== 0 || quartersAmount < (toppingsFree*4))){
          if(this.state.hasError){
            this.setState({hasError: false})
          }
        }
        else{
            if(!this.state.hasError){
              this.setState({hasError: true})
            }
            return;
        }
      }
      
      // const quartersAmount = this.getQuartersAmount(); 
      // const emptysToppingQuarters = topping.quarterList.filter(q => !q).length;
      // if((((maxToppings*4) <= quartersAmount) || (toppingsAddPaid === 0 && (toppingsFree*4) <= quartersAmount)) && !onWholeTray && !maxToppingsIsOff){
      //   !this.state.hasError && this.setState({hasError: true})
      //   return;
      // }
      // else{
      //   this.state.hasError && this.setState({hasError: false})
      // }
      

      //////////////////////////////


      //make topping with(or not) limited
      const newTopping = {...topping, quarterList: []}
      if(onWholeTray){ //remove topping to all quarters
        newTopping.quarterList = Array(4).fill(false);
      }
      else{ //add topping to some/all of the quarters
        newTopping.quarterList = [...topping.quarterList]
        let leftQuartersAmount = (toppingsAddPaid === 0 && toppingsFree < maxToppings) ? (toppingsFree*4)-quartersAmount : (maxToppings*4)-quartersAmount; 
        leftQuartersAmount = leftQuartersAmount < 0 && toppingsAddPaid === 0 ? 4 + leftQuartersAmount : leftQuartersAmount
        if(leftQuartersAmount < emptysToppingQuarters && (!maxToppingsIsOff || toppingsAddPaid === 0)){  //add topping to SOME of the quarters
          let count = 0;
          topping.quarterList.forEach((quarter,q_idx) => {
            if(count < leftQuartersAmount){
              if(!quarter){
                newTopping.quarterList[q_idx] = true;
                count++;
              }
            }
          })
        }
        else{  //add topping to ALL of the quarters
          newTopping.quarterList = Array(4).fill(true);
        }
      }

      // const newTopping = {...topping, quarterList: onWholeTray ? Array(4).fill(false) : Array(4).fill(true)};
      let newSelectedToppings = [];
      this.state.selectedToppings.forEach(t => {
        if(t.id === newTopping.id){
          newSelectedToppings.push(newTopping);
        }
        else{
          const ql = [...t.quarterList] //new quarterList
          const newT = {...t, quarterList: ql}
          newSelectedToppings.push(newT);
        }
      })
      
      //updating 'selectedToppings' state with the new topping
      this.setState({
        selectedToppings: newSelectedToppings
      })
  
      //update option changes
      editCartItemUpdating(this.state.selectedProductOption, newSelectedToppings, this.state.selectedBaseToppings)
  
      //calculate total price
      calcTotalCartPrice();
    }
  }

  // calculate the sum of toppings in all the quarters
  getQuartersAmount = () => {
    let count = 0;
    this.state.selectedToppings.forEach(topping => {
      topping.quarterList.forEach(quarter => {
        count += quarter ? 1 : 0;
      })
    })
    return count;
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    const { cartItemUpdating,
            editCartItemUpdating,
            calcTotalCartPrice,
            packItemUpdating,
            isPackItemUpdating } = this.props.myShopApiStore;
    const { maxToppings } = isPackItemUpdating ? packItemUpdating.item.product : cartItemUpdating.item.product;
    const { shopProductOptions, shopToppings } = isPackItemUpdating ? packItemUpdating.item : cartItemUpdating.item;
    const { toppingsFree } = this.props;
    let toppingsAddPaid = null;
    let productType = cartItemUpdating.item.product.productType;
    
    if(isPackItemUpdating){
      productType = packItemUpdating.item.product.productType
      toppingsAddPaid = packItemUpdating.toppingsAddPaid;
    }
    

    if(name === "selectedBaseToppings"){
      //value === id
      
      const isChecked = this.state.selectedBaseToppings.some(t => t.id == value)
      console.log("isChecked:",isChecked)
      const newSelectedBaseToppings = isChecked
                                      ? 
                                        this.state.selectedBaseToppings.filter(t => t.id != value)
                                      :
                                        [...this.state.selectedBaseToppings, {id: value}]

      console.log("newSelectedBaseToppings:",newSelectedBaseToppings)

      //updating 'selectedBaseToppings' state with the new topping
      this.setState({
        selectedBaseToppings: newSelectedBaseToppings
      })
      
      editCartItemUpdating(this.state.selectedProductOption,
                           this.state.selectedToppings,
                           newSelectedBaseToppings)

     
    }
    else if(name === "selectedToppings"){ //update checked toppings
      const toppingOption = shopToppings.filter(topOption => topOption.topping.name == value)

      const topping = {
        name: toppingOption[0].topping.name,
        price: toppingOption[0].price,
        id: toppingOption[0].id,
        codeName: toppingOption[0].topping.codename,
        quarterList: [false, false, false, false]
      }

      const isValueInArray = this.state[name].some(topping => topping.name === value);
      const quartersAmount = this.getQuartersAmount(); 
      const toppingsAmount = this.state[name].length;
      let toppings = null;

      const maxToppingsIsOff = maxToppings === null || maxToppings === undefined || maxToppings === 0;

      //pizza item
      if(productType === 'pizza'){
        toppings = isValueInArray
                    ? 
                      this.state[name].filter(topping => topping.name !== value) 
                    : 
                      (((maxToppings*4) > quartersAmount || maxToppingsIsOff) &&
                        (toppingsAddPaid !== 0 || quartersAmount < (toppingsFree*4)))
                      ? 
                        [...this.state[name], topping] 
                      : 
                        this.state[name];
      }
      //regular item
      else{
        toppings = isValueInArray
                    ? 
                      this.state[name].filter(topping => topping.name !== value) 
                    : 
                      (maxToppings > this.state.selectedToppings.length || maxToppingsIsOff) && (toppingsAddPaid !== 0 || toppingsFree > this.state.selectedToppings.length)
                      ? 
                        [...this.state[name], topping] 
                      : 
                        this.state[name];
      }

      if(toppingsAmount === toppings.length){
        !this.state.hasError && this.setState({hasError: true})
      }
      else{
        this.state.hasError && this.setState({hasError: false})
      }

      //clear checkbox toppings picked of pizza item that not on pizza quarters
      let newToppings = null;
      if(productType === 'pizza'){
        newToppings = toppings.filter(t => t.quarterList.some(q => q) || t.id === topping.id);
      }

      this.setState({
        [name]: newToppings ? newToppings : toppings
      })

      editCartItemUpdating(this.state.selectedProductOption, newToppings ? newToppings : toppings, this.state.selectedBaseToppings)
    }
    else if (name === "selectedProductOption"){ // update product option
      const productOption = shopProductOptions.filter(po => po.productOption.name === value)
      const option = {
        name: productOption[0].productOption.name,
        price: productOption[0].price,
        id: productOption[0].id,
      }
      this.setState({
        [name]: option,
      });

      editCartItemUpdating(option, this.state.selectedToppings, this.state.selectedBaseToppings)
    }

    calcTotalCartPrice();

  };

  cancleUpdateCartItem = () => {
    const { editCartItemUpdating,
            calcTotalCartPrice,
            closeCartItemForm,
            isPackItemUpdating,
            closePackItemForm,
            setStepNum,
            isOpenForEdit } = this.props.myShopApiStore;
      
      //update unsave options
      editCartItemUpdating(this.state.optionsHistory.productOption, this.state.optionsHistory.toppings, this.state.selectedBaseToppings);
      calcTotalCartPrice();
    
    //close form
    if(isPackItemUpdating){
      setStepNum(1);
      closePackItemForm();
    }
    else{
      closeCartItemForm();
    }

    if(!isOpenForEdit && !isPackItemUpdating){ // if its the initial edit (and not packitem) -> remove from cart!
      const { removeCartItem, getCart } = this.props.myShopApiStore;
      const { cartItemList } = getCart();
      const idx = cartItemList.length - 1;
      removeCartItem(idx);
    }
  }

  removeTopping = (topping) => {
    const { calcTotalCartPrice, editCartItemUpdating } = this.props.myShopApiStore;

    //updating 'selectedToppings' state with the new topping
    let newToppings = [...this.state.selectedToppings.filter(t => t.codeName !== topping.codeName)];
    if(this.props.toppingsFree){ //update firsts free toppings price
      for(let i=0 ; i < newToppings.length ; i++){
        if(i < this.props.toppingsFree){
          newToppings[i].price = 0;
        }
      }
    }
    this.setState({
      selectedToppings: newToppings
    })

    //update option changes
    editCartItemUpdating(this.state.selectedProductOption,
            this.state.selectedToppings.filter(t => t.codeName !== topping.codeName),
            this.state.selectedBaseToppings);

    //calculate total price
    calcTotalCartPrice();
  }

  _next = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep >= 2 ? 2 : currentStep + 1;
    this.setState({
      currentStep: currentStep,
    });
  };

  _prev = () => {
    let currentStep = this.state.currentStep;
    // currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    currentStep = currentStep <= 0 ? 0 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  };

  /*
   * the functions for our button
   */
  previousButton() {
    const { cartItemUpdating, packItemUpdating, isPackItemUpdating, mainColor } = this.props.myShopApiStore;
    const { shopProductOptions, shopBaseToppings } = isPackItemUpdating ? packItemUpdating.item : cartItemUpdating.item;
    const hasProductOptions = shopProductOptions ? shopProductOptions.length : false;
    const hasBaseToppings = shopBaseToppings?.length > 0;
    // if (this.state.currentStep !== 1 && hasProductOptions) {
    if (hasBaseToppings ? this.state.currentStep !== 0 : this.state.currentStep !== 1 && hasProductOptions) {
      return (
        <button
          type="button"
          onClick={this._prev}
          style={{color: mainColor, fontSize: '15px', border: 'none',
                  height: 'min-content', width: 'max-content',
                  padding: 0, margin: 0}}
        >
          הקודם
        </button>
      );
    }
    return null;
  }

  nextButton() {
    let currentStep = this.state.currentStep;
    const { mainColor } = this.props.myShopApiStore;
    if (currentStep < 2) {
      return (
        <button 
          type="button" 
          onClick={this._next}
          style={{color: mainColor, border: `2px solid ${mainColor}`}}
          >
          הבא
        </button>
      );
    }
    return null;
  }

  render() {
    const { cartItemUpdating, packItemUpdating, isPackItemUpdating, mainColor } = this.props.myShopApiStore;

    const { shopBaseToppings,
            shopProductOptions,
            shopToppings,
            product } = isPackItemUpdating ? packItemUpdating.item : cartItemUpdating.item;

    const { optionsDescription,
            toppingsDescription,
            baseToppingsDescription,
            productType,
            maxToppings } = product;
    
    const { optionsPaid } = isPackItemUpdating && packItemUpdating;
    
    const hasToppings = shopToppings ? shopToppings.length > 0 : false;
    const hasProductOptions = shopProductOptions ? shopProductOptions.length > 0 : false;


    let toppingsAddPaid = null;
    
    if(isPackItemUpdating){
      toppingsAddPaid = packItemUpdating.toppingsAddPaid;
    }

    const title = isPackItemUpdating ? packItemUpdating.item.product.name : cartItemUpdating.item.product.name;

    return (
      <div className={style.Container}>
        <div className={style.TopContent}>
          <div className={style.CloseButton}>
            <button onClick={this.cancleUpdateCartItem}>✕</button>
          </div>
          <div className={style.ItemName} title={title} style={{color: mainColor}}>
            {title}
          </div>
        </div>

        {/* <div className={style.BodyContent}> */}
          <form onSubmit={this.handleSubmit}>
            {
              <SubForm0
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                shopBaseToppings={shopBaseToppings}
                selectedBaseToppings={this.state.selectedBaseToppings}
                baseToppingsDescription={baseToppingsDescription}
                optionsPaid={optionsPaid}
              />
            }
            <SubForm1
              currentStep={this.state.currentStep}
              handleChange={this.handleChange}
              shopProductOptions={shopProductOptions}
              selectedProductOption={this.state.selectedProductOption}
              optionsDescription={optionsDescription}
              optionsPaid={optionsPaid}
            />
            {
              productType === "pizza" ? 
              <SubForm3
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                shopToppings={shopToppings}
                selectedToppings={this.state.selectedToppings}
                selectedProductOption={this.state.selectedProductOption}
                toppingsDescription={toppingsDescription}
                toppingsFree={this.props.toppingsFree}
                setQuarterList={this.setQuarterList}
                toggleToppingOnQuarter={this.toggleToppingOnQuarter}
                toggleToppingOnAllQuarters={this.toggleToppingOnAllQuarters}
                removeTopping={this.removeTopping}
                optionsPaid={optionsPaid}
                hasError={this.state.hasError}
                hasProductOptions={hasProductOptions}
                maxToppings={maxToppings}
                toppingsAddPaid={toppingsAddPaid}
                closeMaxToppingMsg={this.closeMaxToppingMsg}
                mainColor={mainColor}
              />
              :
              <SubForm2
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                shopToppings={shopToppings}
                selectedToppings={this.state.selectedToppings}
                toppingsDescription={toppingsDescription}
                toppingsFree={this.props.toppingsFree}
              />
            }
            
            <div className={style.FormButtons}>
              {
                hasToppings
                ?
                  <div className={style.NextBtn}>{this.nextButton()}</div>
                :
                  null
              }
              <div className={style.PrevAndSubmitBtns}>
                <div className={style.SubmitFormBtn}>
                  {(this.state.currentStep === 2 || !hasToppings) && 
                    <button onClick={this.handleSubmit}
                      style={{border: `2px solid ${mainColor}`, color: mainColor}}>הוסף לסל</button>}
                </div>
                <div className={style.PreviousBtn}>{this.previousButton()}</div>
              </div>
            </div>
          </form>
        {/* </div> */}


      </div>
    );
  }
}

export default ItemUpdateForm;
